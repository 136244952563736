/**
 * Expresión regilar para validar la cadena de un formato de fecha ISO8601 ejem. 2022-07-03T16:08:32.911Z
 */
const DateStringISO8601 = /^(\d{4})(-(\d{2}))??(-(\d{2}))??(T(\d{2}):(\d{2})(:(\d{2}))??(\.(\d+))??(([+-]{1}\d{2}:\d{2})|Z)??)??$/g

/**
 * Expresión regilar para validar la cadena de un formato de hora
 */
const TimeString = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/g
/**
 * Contraseña que debe contener:
 * ° Una letra mayuscula
 * ° Una letra minuscula
 * ° Un Numero
 * ° Un caracter especial
 * ° Al menos 6 caracteres
 */
const PasswordPulmeros = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){6,}$/g

/**
 * Formato para los rfc's que pueden ser morales o fisicos
* 1.- 3 o 4 letras que componen el nombre de la persona -> MCP ó EKJM
* 2.- Fecha de nacimiento (YY-MM-DD)-> 860623
* 3.- Homoclave asignada por hacienda -> XL1
 */
const RfcFormat = /^([A-ZÑ&]{3,4})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([A-Z\d]{2})([A\d])$/g

/**
 * Separadores que pueden aparecer en un formato de telefono
 */
const separatorsPhone = /[-() +.]/g
/**
 * Formato de numero telefonico agrupado de la sigueinte manera "9999 45 78 25"
 */
const Phone10Digits = /^(\d{4})(\d{2})(\d{2})(\d{2})$/g

/**
 * Numeros Enteros o flotantes a dos decimales con maximo el valor de 99.99% y minio 0.00%
 */
const FloatNumber = /^((([0]{1})|([1-9]{1,2}0?))(\.?)([0-9]{1,2})?(\\%)?)$/g

/**
 * Limpia el valor enviado y deja solo numeros
 * @param value valor a limpiar
 * @returns 
 */
const LeaveNumbers = (value): string => value.replace(/\D/g, '');

export { DateStringISO8601 
    , PasswordPulmeros
    , RfcFormat
    , separatorsPhone
    , Phone10Digits
    , FloatNumber
    , TimeString
    , LeaveNumbers
}
