/**
 * Convierte un numero en una cadena con el formato de numero adecuado
 * @param number numero a formatear
 * @returns cadena con el formato de numero
 */
const formatNumeric = (number: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',//esto puede ser configurable con i18n...
        minimumFractionDigits: 2
    });
    return formatter.format(number);
}

const formatNumeric2 = (number: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',//esto puede ser configurable con i18n...
        minimumFractionDigits: 4
    });
    return formatter.format(number);
}
/**
 * Remueve el formato de moneda de una cantidad
 * @param amount cantidad en formato de moneda
 * @returns un numero con el valor correspondiente
 */
const removeCurrencyFormat = (amount: string): number => {
    return Number((amount).replace(/[^0-9.-]+/g,""));
}

export {formatNumeric, formatNumeric2, removeCurrencyFormat}