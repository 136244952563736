
import { useField } from "vee-validate";
import { defineComponent, PropType, ref, toRefs, watch } from "vue";
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import { FloatNumber, LeaveNumbers } from "@/core/shared/ValidationRegex";
import {debounce} from 'lodash'
import { formatNumeric, removeCurrencyFormat, formatNumeric2 } from "@/core/shared/functions/Formatter";
import { IMask,  } from 'vue-imask';
import { KeyCodes } from "@/core/enums/key-codes";
export default defineComponent({
    props:{
        name: {
            type: String
            , required: true
        }, 
        disabled: {
            type: Boolean
            , default: false
        },
        placeholder: {
            type: String
            , default: ''
        },
        mask: {
            type: Number as PropType<InputTextMaskTypes>
            , default: InputTextMaskTypes.Default
        },
        //Indica si la mascara se mantendra en el valor del texto
        keepMask: {//para los casos que sea upper o lower regresa siempre el valor de la mascara si esto es false
            type: Boolean
            , default: false
        },
        size: {
            type: String
            , default: 'default'
        }
    },
    emits:['change', 'input'],
    setup(props, {emit}){
        const { keepMask, mask } = toRefs(props)

        //const { value } = useField(props.name + "_imask");
        const valueMask = ref('')
        const cleanValue = useField(props.name);
        const lengthPrevius = ref(0);
        const deleteAction = ref(false);//indica si la entrada de texto es una eliminación
        /**
         * Agrega la mascara 
         */
        const maskPhone = (value) => {
            const cleaned = ('' + value).replace(/\D/g, '');
            //si es igual se esta removiendo un digito 
            //si no es igual se esta agregando un digito mas
            lengthPrevius.value == cleaned.length ? lengthPrevius.value-- : (lengthPrevius.value = cleaned.length);
            
            const numbers = cleaned.split('', lengthPrevius.value)
            if(numbers.length == 0) return ''

            let aaa = "X".repeat(10)
                .split('')
                .map((char, index) => {
                    const digit = numbers[index] ? numbers[index] : char;
                    return digit
                }).join("");

            const match = (/^(\w{4})(\w{2})(\w{2})(\w{2})$/g).exec(aaa)
            return match ? `(${match[1]})-${match[2]}-${match[3]}-${match[4]}` : null;
            
            
        }
         const maskPercentage = (entry: string) => {
        
            let newValue = 0;//solo quedan numeros
            let realEntry = LeaveNumbers(entry);
            if(deleteAction.value){
                realEntry = realEntry.slice(0, lengthPrevius.value -1/**quitamos uno por el simbolo -> % */);
            }
            //validamos que solo pueda agregar hasta el porcentaje de 100%
            if(!isNaN(parseFloat(realEntry))){
                newValue = Number.parseInt(realEntry);
                newValue = newValue > 100 ? 100 : (newValue < 0 ? 0 : newValue);
            }
            //guardamos el nuevo length
            //lengthPrevius.value = (newValue+'').length;
            //dams formato
            return (newValue+'%').replace(/\B(?=(\d{1,3})(?!%))/g, '')
        }


        const formater = (value: string) => {
            if(!value && value.length <= 0) {return ''}
            switch (mask.value) {
                case InputTextMaskTypes.PhoneMex:
                    return maskPhone(value);
                case InputTextMaskTypes.Lowercase:
                    return value.toLowerCase();
                case InputTextMaskTypes.Uppercase:
                    return value.toUpperCase();
                case InputTextMaskTypes.Percentage:
                    return maskPercentage(value);
                case InputTextMaskTypes.Money:
                    return formatNumeric(+value)
                case InputTextMaskTypes.MoneyShopping:
                    return formatNumeric2(+value)
                case InputTextMaskTypes.Number:
                case InputTextMaskTypes.Default:
                default:
                    return value;
               

            }
        }
        /**
         * Cambia el formato que se vera en el input
         */
        const parser = (value) => {
            let returnValue: number | string;
            if(keepMask.value) {return value}
            switch (mask.value) {
                case InputTextMaskTypes.PhoneMex:
                    returnValue = (value).replace(/\D/g, '');
                    break;
                case InputTextMaskTypes.Percentage:
                    deleteAction.value = lengthPrevius.value >= value.length;
                    returnValue = value//.replace(/\d\s*%/g, '')
                    break;
                case InputTextMaskTypes.Number:
                    returnValue = LeaveNumbers(value);
                    break;
                case InputTextMaskTypes.Money:
                    returnValue = removeCurrencyFormat(value);
                    break;
                case InputTextMaskTypes.Lowercase:
                case InputTextMaskTypes.Uppercase:
                case InputTextMaskTypes.Default:
                default:
                    returnValue = value;
            }
            lengthPrevius.value = (returnValue+'').length;
            return returnValue;
        }
        const clear = () => {
            masked.value.reset();
            cleanValue.value.value = '';
            valueMask.value = ''
        } 
        const masked = ref(IMask.createMask({mask: /\w+/}));//se crea la mascara vacia
        switch (mask.value) {
            case InputTextMaskTypes.PhoneMex:
                /*masked.value.updateOptions({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                      mask: '(0000) 00-00-00',
                        lazy: false
                    , placeholderChar: '#'
                    //, overwrite: true
                })*/
                 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                masked.value = IMask.createMask({
                     mask: '(0000) 00-00-00',
                        lazy: false
                    , placeholderChar: '#'
                })
                break
            case InputTextMaskTypes.Lowercase:
                masked.value.updateOptions(
                    IMask.createMask({
                        mask: /\w+/
                        ,  prepareChar: function (str) {
                            return str.toLowerCase();
                        },
                }));
                break
            case InputTextMaskTypes.Uppercase:
                 masked.value.updateOptions(
                    IMask.createMask({
                        mask: /\w+/
                        ,  prepareChar: function (str) {
                            return str.toUpperCase();
                        },
                }));
                break
            case InputTextMaskTypes.Percentage:
                 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                masked.value = IMask.createMask({
                     mask: '00[{.}00]{%}',
                    definitions: {
                        // <any single char>: <same type as mask (RegExp, Function, etc.)>
                        // defaults are '0', 'a', '*'
                        '#': /\d[\d|\\.]+/
                    }
                })
                break;
            case InputTextMaskTypes.Money:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                masked.value = IMask.createMask({
                     mask: [
                        { mask: '' },
                        {
                            mask: '$num',
                            lazy: false,
                            blocks: {
                                num: {
                                    mask: Number,
                                    scale: 2,
                                    thousandsSeparator: ',',
                                    padFractionalZeros: false,
                                    normalizeZeros: true,
                                    radix: '.',
                                    expose: true,
                                }
                            }
                        }
                    ]
                });
                break;
            case InputTextMaskTypes.MoneyShopping:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                masked.value = IMask.createMask({
                     mask: [
                        { mask: '' },
                        {
                            mask: '$num',
                            lazy: false,
                            blocks: {
                                num: {
                                    mask: Number,
                                    scale: 4,
                                    thousandsSeparator: ',',
                                    padFractionalZeros: false,
                                    normalizeZeros: true,
                                    radix: '.',
                                    expose: true,
                                }
                            }
                        }
                    ]
                });
                break;
            case InputTextMaskTypes.Number:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                masked.value = IMask.createMask({
                     mask: Number,
                    scale: 2,  // digits after point, 0 for integers
                    thousandsSeparator: ',',  // any single char
                    padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
                    normalizeZeros: true,  // appends or removes zeros at ends
                    radix: '.',  // fractional delimiter
                    mapToRadix: ['.'],  // symbols to process as radix
                })
                break
            case InputTextMaskTypes.Default:
            default:
                masked.value.updateOptions(
                    IMask.createMask({
                        mask: /\w+/
                }));
                break
        }
        
        const change = (entry) => {
            entry = entry ?? '';
            if(deleteAction.value) {
                const previusLenght = (mask.value == InputTextMaskTypes.PhoneMex) 
                    ? masked.value.value.replace(/[\\(\\)\\#\s\\-]+/g, '').length
                    : masked.value.value.length;
                entry = (mask.value == InputTextMaskTypes.PhoneMex) 
                    ? entry.replace(/[\\(\\)\\#\s\\-]+/g, '')
                    : entry;
                if(entry.length != (previusLenght-1))
                    entry = entry.substring(0,entry.length-1);
            }
            
            masked.value.value  = entry;  

            valueMask.value = masked.value.value;//valor con mascara
            if(keepMask.value)
                cleanValue.value.value = masked.value.value;
            else
                cleanValue.value.value = masked.value.unmaskedValue
            
            emit('input', cleanValue.value.value)
        }
        const keydown = (event) => {
            const keyCode = event.keyCode;

            if(keyCode == KeyCodes.BackSpace || keyCode == KeyCodes.Delete)
                deleteAction.value = true;
            else
                deleteAction.value = false;
        }

        watch(cleanValue.value, (currentValue) => {
            let valueCurrent = currentValue ?? '';
            deleteAction.value = false;
            change(valueCurrent+'');
        })
        return {
            valueMask
            , errorMessage: cleanValue.errorMessage
            , formater
            , parser
            , clear
            , change
            , keydown
        }
    }
})
