import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_form_item, { error: _ctx.errorMessage }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.valueMask,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueMask) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change($event))),
          onKeydown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.keydown($event))),
          size: _ctx.size,
          "validate-event": false,
          disabled: _ctx.disabled,
          placeholder: _ctx.placeholder
        }, null, 8, ["modelValue", "size", "disabled", "placeholder"])
      ]),
      _: 1
    }, 8, ["error"])
  ]))
}