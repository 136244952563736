/**
 * Tipos de mascaras para el input de texto
 */
 export enum InputTextMaskTypes {
     Default = 0 
    , PhoneMex = 1//numero telefonico a 10 digitos en formato mexicano
    , Lowercase = 2//minuscula
    , Uppercase = 3//mayuscula
    , Percentage
    , Number
    , Money
    , MoneyShopping
}